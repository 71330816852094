let upcomingEventsData;
let gunClubData;
const currDate = Date.now();

const locationMap = {
   proGun: document.querySelector(".pro-gun"),
   clarkCounty: document.querySelector(".clark-county"),
   walkerRiver: document.querySelector(".walker-river"),
   perdizSports: document.querySelector(".perdiz-sports"),
   springCreek: document.querySelector(".spring-creek"),
   cvc: document.querySelector(".cvc"),
   ccgc: document.querySelector(".ccgc"),
   pf: document.querySelector(".pf"),
};

const getShootData = async function () {
   try {
      const response = await fetch("./ShootDates.json");
      upcomingEventsData = await response.json();
   } catch (err) {
      alert(err.message);
   }
};

const getClubData = async function () {
   try {
      const response = await fetch("./GunClubData.json");
      gunClubData = await response.json();
   } catch (err) {
      alert(err.message);
   }
};

/* ********************************* */
/* Homepage Short List Section */
/* ********************************* */

const upcomingEventList = document.querySelector(".upcoming-shoot-list");

const filterData = async function () {
   await getShootData();
   await getClubData();
   upcomingEventsData.forEach((event) => {
      const clubInfo = gunClubData.find((club) => club.clubName === event.clubName);
      if (clubInfo) {
         event.clubWebsite = clubInfo.clubWebsite;
         event.shootLocation = clubInfo.shootLocation;
      } else {
         console.warn("No club data found for", event.clubName);
      }
   });

   return upcomingEventsData.filter((event) => {
      const startDate = new Date(event.startDate);
      return startDate > currDate && event.shootLocation;
   });
};

const outputHTMLShort = async function () {
   const upcomingShoots = await filterData(); // Assign the filtered data to a variable
   upcomingShoots.slice(0, 6).forEach((el) => {
      // Change map to forEach if you're not returning an array
      const shootName = el.shootName;
      const clubName = el.clubName;
      const startDate = new Date(el.startDate);
      const endDate = new Date(el.endDate);
      const dateOptions = { year: "numeric", month: "long", day: "numeric" };
      const clubWebsite = el.clubWebsite;

      const outputHTML =
         startDate.getTime() !== endDate.getTime()
            ? `
           <li class="upcoming-shoot-item">
           <div>
           <h3>${shootName}</h3>
           <a target="_blank" href=${clubWebsite} class="indent">${clubName}</a>
           <p class="indent">${startDate.toLocaleDateString(
              "us-en",
              dateOptions
           )} - ${endDate.toLocaleDateString("us-en", dateOptions)}</p>
           </div>
           </li>`
            : `
           <li class="upcoming-shoot-item">
           <div>
           <h3>${shootName}</h3>
           <a target="_blank" href=${clubWebsite} class="indent">${clubName}</a>
           <p class="indent">${startDate.toLocaleDateString("us-en", dateOptions)}</p>
           </div>
           </li>`;

      upcomingEventList.insertAdjacentHTML("beforeend", outputHTML);
   });
};

if (upcomingEventList) {
   outputHTMLShort();
}

/* ********************************* */
/* Shoots List Section */
/* ********************************* */

const shootPage = document.querySelector(".club-shoot-list");

function outputHTML(shootLocation, shootName, startDate, endDate) {
   const dateOptions = { year: "numeric", month: "long", day: "numeric" };

   const html = `
  <li class="upcoming-shoot-item">
  <div>
  <h3>${shootName}</h3>
  <p class="indent">${startDate.toLocaleDateString(
     "us-en",
     dateOptions
  )} - ${endDate.toLocaleDateString("us-en", dateOptions)}</p>
  </div>
  </li>`;
   shootLocation.insertAdjacentHTML("beforeend", html);
}
const fullShootsList = async function () {
   const upcomingShoots = await filterData();

   upcomingShoots.forEach((event) => {
      const shootLocation = locationMap[event.shootLocation];

      if (shootLocation) {
         outputHTML(
            shootLocation,
            event.shootName,
            new Date(event.startDate),
            new Date(event.endDate)
         );
      } else {
         console.error("Element not found for", event.shootLocation, "in event", event);
      }
   });
};

if (shootPage) {
   fullShootsList();
}
